import { LatLng, LatLngBounds } from 'leaflet';
import React, { useCallback, useEffect } from 'react';
import { useMap } from 'react-leaflet';
import { useDispatch, useSelector } from 'react-redux';
import Analytics from '../../../lib/user-analytics';
import { actionSetMapBounds, actionSetMapMoveEnd, actionSetMapZoom } from '../../../store/App/actions';
import { selectFlyToMapPosition, selectMapBounds, selectMapZoom } from '../../../store/App/selectors';
import { selectBeingMobileSentinelSearch, selectIsMobileInteractionEnabled } from '../../../store/Map/Mobile/selectors';
import { actionSentinelMapZoom, actionSentinelSelectAOI } from '../../../store/Map/Sentinel/actions';
import UriHelper from '../../../lib/uri-helper';

// Level to zoom to when focusing on a point
const FLYTO_ZOOM_LEVEL = 11;

// Force the map back to these bounds while panning
const WORLD_DRAG_REBOUND_BOUNDS: [number, number][] = [
    [-80.98155760646617, -220],
    [89.99346179538875, 240],
];

const MobileMapDispatcher = () => {
    const isMobileMapInteractionEnabled = useSelector(selectIsMobileInteractionEnabled);
    const beginMobileSentinelSearch = useSelector(selectBeingMobileSentinelSearch);
    const mapPosition = useSelector(selectFlyToMapPosition);
    const mapBounds = useSelector(selectMapBounds);
    const mapZoomLevel = useSelector(selectMapZoom);

    const map = useMap();
    const dispatch = useDispatch();

    const setMapZoomLevel = useCallback(
        (zoom: number) => {
            dispatch(actionSetMapZoom(zoom));
            dispatch(actionSentinelMapZoom(zoom));
        },
        [dispatch]
    );

    const flyTo = useCallback(
        (position: LatLng | LatLngBounds) => {
            if (position instanceof LatLng) {
                const params = new URLSearchParams(window.location.search);
                const zoom = parseInt(params.get('pos')?.split(',')[2] || '');
                if (zoom && !Number.isNaN(zoom)) {
                    map.flyTo(position, zoom, { animate: false });
                } else {
                    map.flyTo(position, FLYTO_ZOOM_LEVEL, { animate: false });
                }
            } else if (position instanceof LatLngBounds) {
                map.flyToBounds(position, { animate: false });
            }
        },
        [map]
    );

    useEffect(() => {
        if (mapPosition) {
            dispatch(actionSetMapBounds(undefined));
            requestAnimationFrame(() => {
                flyTo(mapPosition);
            });
        }
    }, [dispatch, flyTo, map, mapPosition]);

    useEffect(() => {
        const setSentinelAOI = (bbox: LatLngBounds) => dispatch(actionSentinelSelectAOI(bbox));

        if (beginMobileSentinelSearch && map) {
            setSentinelAOI(map.getBounds());
            Analytics.Event('Mobile Map', 'Created sentinel AOI', map.getBounds());
        }
    }, [beginMobileSentinelSearch, dispatch, map]);

    useEffect(() => {
        if (!map) return;

        // Prevent the map from wrapping by forcing in to pan inside world bounds
        map.on('drag', () => {
            map.panInsideBounds(WORLD_DRAG_REBOUND_BOUNDS, { animate: false });
        });

        map.on('zoomend', () => {
            map.panInsideBounds(WORLD_DRAG_REBOUND_BOUNDS, { animate: false });
            setMapZoomLevel(map.getZoom());
            dispatch(actionSetMapBounds(map.getBounds()));
        });

        const handleMoveStart = () => {
            dispatch(actionSetMapMoveEnd(false));
        };

        const handleMoveEnd = () => {
            dispatch(actionSetMapMoveEnd(true));
            dispatch(actionSetMapBounds(map.getBounds()));
        };

        map.on('movestart', handleMoveStart);
        map.on('zoomstart', handleMoveStart);

        map.on('moveend', handleMoveEnd);
        map.on('zoomend', handleMoveEnd);

        dispatch(actionSetMapBounds(map.getBounds()));
        setMapZoomLevel(map.getZoom());
    }, [map, dispatch, setMapZoomLevel]);

    useEffect(() => {
        if (!map) return;

        // The mobile-map.tsx component gets reloaded every time the tabbar state is changed
        // This causes the maps bounds to reset to the default bounds, so we need to re-set them from redux state
        // Ideally, the map component would not be reloaded for tabbar state changes
        if (mapBounds && (mapBounds as LatLngBounds)) {
            map.flyToBounds(mapBounds as LatLngBounds, { animate: false });
        }

        if (isMobileMapInteractionEnabled) {
            map.dragging.enable();
            map.touchZoom.enable();
            map.doubleClickZoom.enabled();
            map.scrollWheelZoom.enabled();
            map.boxZoom.enabled();
            map.keyboard.enabled();
        } else {
            map.dragging.disable();
            map.touchZoom.disable();
            map.doubleClickZoom.disable();
            map.scrollWheelZoom.disable();
            map.boxZoom.disable();
            map.keyboard.disable();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMobileMapInteractionEnabled, map]);

    // We prevent the map from wrapping (below) however in Hawaii it's possible to
    // view the map on both sides of the Anti-meridian eg longitude 200 && -150
    // which causes "No maps" and "No sentinel results" bugs like #3039
    // This wraps the viewport back to world bounds -180 .. 180
    const fixAntiMeridianIfRequired = useCallback(() => {
        if (map) {
            const center = map.getCenter();
            const zoom = map.getZoom();

            if (zoom >= 5) {
                const wrappedCenter = center.wrap();

                if (!wrappedCenter.equals(center)) {
                    map.flyTo(wrappedCenter, zoom, { animate: false });
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (map) {
            // Set an initial position parameter if not already set.
            // Prevents a bug where the user opens a listing without moving the map, then pressing back
            if (!UriHelper.tryGetParam('pos')) {
                const zoom = Number(map.getZoom().toFixed(2));
                dispatch(actionSetMapZoom(zoom));
                const center = map.getCenter();
                const value = center.lat.toString() + ',' + center.lng.toString() + ',' + zoom;
                UriHelper.addParameterToUri('pos', value);
            }

            // Prevent the map from wrapping by forcing in to pan inside world bounds
            map.on('drag', () => {
                map.panInsideBounds(WORLD_DRAG_REBOUND_BOUNDS, { animate: false });
            });

            map.on('zoomend', () => {
                map.panInsideBounds(WORLD_DRAG_REBOUND_BOUNDS, { animate: false });
                fixAntiMeridianIfRequired();

                const zoom = Number(map.getZoom().toFixed(2));
                dispatch(actionSetMapZoom(zoom));
                const center = map.getCenter();
                const value = center.lat.toString() + ',' + center.lng.toString() + ',' + zoom;
                UriHelper.addParameterToUri('pos', value);
                return;
            });

            map.on('moveend', () => {
                fixAntiMeridianIfRequired();

                const zoom = Number(map.getZoom().toFixed(2));
                const center = map.getCenter();
                const bounds = map.getBounds();
                dispatch(actionSetMapBounds(bounds));

                const value = center.lat.toString() + ',' + center.lng.toString() + ',' + zoom;
                UriHelper.addParameterToUri('pos', value);
                return;
            });

            if (!mapZoomLevel) {
                const zoom = Number(UriHelper.tryGetParam('pos')?.split(',')[2]) || map.getZoom();
                dispatch(actionSetMapZoom(zoom));
            }

            if (!mapBounds) {
                const bounds = map.getBounds();
                dispatch(actionSetMapBounds(bounds));
            }

            map.attributionControl.setPrefix('');
        }

        return () => {
            dispatch(actionSetMapZoom(undefined));
            dispatch(actionSetMapBounds(undefined));
        };

        // Only trigger on map
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [map]);

    return <React.Fragment />;
};

export default MobileMapDispatcher;
